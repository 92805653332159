<template>
  <moe-page title="查看秒杀活动">
    <moe-form
      class="default-form"
      :showBack="false"
      :showClose="true">
      <div style="width: 50%;" class="margin-0-auto">
        <div class="fwb mb-20 font-20">基本信息</div>

        <moe-describe-list title="" :col="1">
          <moe-describe-item label="活动名称">{{ flashData.name }}</moe-describe-item>
          <moe-describe-item label="使用时间">{{ `${flashData.startTime} 至 ${flashData.endTime}` }}</moe-describe-item>
          <moe-describe-item label="活动名称">
            <div class="df aic">
              <div>{{ `活动开始前 ${flashData.preheatTime} 小时开始预热` }}</div>
              <el-tooltip class="ml-10" content="预热期无法享受秒杀优惠，将在商品详情页和秒杀活动页展示活动价格及活动时间" placement="top">
                <moe-icon name="reminder" size="20px"></moe-icon>
              </el-tooltip>
            </div>
          </moe-describe-item>
        </moe-describe-list>
      </div>
      <div class="fwb mb-20 font-20">活动商品</div>
      <div style="height: 700px;min-height: 700px;" class="df fdc">
        <moe-table
          :stripe="true"
          :data="flashData.configList"
          :numberShow="false"
          :mode="false"
          :showPage="false"
          :params="{ pageNum: 1 }"
          emptyText="请添加活动商品"
          rowKey="goodsId">
          <el-table-column label="商品信息" min-width="200">
            <template slot-scope="{ row }">
              <div class="df aic jcc">
                <moe-image :src="row.coverUrl" width="80px" height="80px" />
                <div class="df1 tal ml-10">
                  <p class="fwb">{{ row.name }}</p>
                  <p class="font-12 color-info">商品编号: {{ row.goodsId }}</p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="销售价" prop="salePrice" min-width="150" :formatter="formatterSalePrice" />
          <el-table-column label="库存" prop="stock" width="80" />
          <el-table-column label="秒杀价" min-width="200">
            <template slot-scope="{ row, $index }">
              <el-button @click="handleGoodsItem(row, $index)" icon="el-icon-circle-check" type="primary">查看设置</el-button>
            </template>
          </el-table-column>
        </moe-table>
      </div>
    </moe-form>

    <moe-dialog :show="form.showDialog" title="设置秒杀优惠" width="70%" @close="handleDialogClose()">
      <div class="dialog_content">
        <moe-table
          :numberShow="false"
          :data="form.list"
          :mode="false">
          <el-table-column label="商品信息" min-width="200">
            <div class="df aic" slot-scope="{ row }">
              <moe-image :src="row.coverUrl" width="80px" height="80px" />
              <div class="ml-10 df fdc aifs">
                <p class="fwb tal">{{ row.goodsName }}</p>
                <p class="font-12 color-info">商品编号: {{ row.goodsId }}</p>
              </div>
            </div>
          </el-table-column>
          <el-table-column label="规格" min-width="200">
            <template slot-scope="{ row }">
              <div class="df aic jcc">
                <moe-tag class="mr-5" v-for="(item, index) in row.specValueStr.split(',')" :key="index">{{ item }}</moe-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="销售价" min-width="150">
            <template slot-scope="{ row }">
              {{ `¥ ${row.salePrice}` }}
            </template>
          </el-table-column>
          <el-table-column label="库存" prop="allStock" min-width="80" />
          <el-table-column label="活动库存" prop="stock" min-width="80" />
          <el-table-column label="秒杀一口价" min-width="150">
            <template slot-scope="{ row }">
              {{ `¥ ${row.price}` }}
            </template>
          </el-table-column>
          <el-table-column label="活动限购" prop="quota" min-width="80" />
        </moe-table>
      </div>
      <template slot="footer">
        <el-button @click="handleDialogClose()">关闭</el-button>
      </template>
    </moe-dialog>
  </moe-page>
</template>

<script>
export default {
  name: 'ActivityManageFlashDetail',
  data() {
    return {
      flashData: {},
      form: {
        showDialog: false,
        list: []
      }
    }
  },
  methods: {
    formatterSalePrice({ minPrice, maxPrice }) {
      return `¥ ${minPrice}${this.$moe_math.mathGreaterThan(maxPrice, minPrice) ? ` - ¥ ${maxPrice}` : ''}`
    },
    handleGoodsItem(row, index) {
      if (this.flashData.configList[index].goodsItemList.length) {
        this.form.list = this.$moe_lodash.cloneDeepData(this.flashData.configList[index].goodsItemList);
      } else {
        this.form.list = [];
      }
      this.form.showDialog = true;
    },
    handleDialogClose() {
      this.form.showDialog = false;
    },
    /** 获取秒杀活动详情 */
    async req_getFlashDetail() {
      if (this.$route.query.id) {
        const { code, result, message } = await this.$moe_api.FLASH_API.getFlashDetail({ id: this.$route.query.id });
        if (code === 200) {
          const { id, name, startTime, endTime, preheatTime, configList } = result;
          this.flashData = {
            id,
            name,
            startTime,
            endTime,
            preheatTime,
            configList: []
          }
          if (configList.length) {
            this.req_getQueryShopGoodsByIds(configList);
          }
        } else {
          this.$moe_msg.error(message);
        }
      }
    },
    /** 根据商品ID获取商品列表 */
    async req_getQueryShopGoodsByIds(goodsList) {
      //数组去重
      let unionByList = this.$moe_lodash.unionByList(goodsList, goodsList, 'goodsId');
      const { code, result, message } = await this.$moe_api.GOODS_API.getQueryShopGoodsByIds({ ids: unionByList.map(({ goodsId }) => goodsId).join(',') });
      if (code === 200) {
        this.flashData.configList = unionByList.map((item) => {
          let findGoodsItem = result.find(({ id }) => item.goodsId === id);
          let goodsItemList = goodsList.filter(({ goodsId }) => item.goodsId === goodsId).map((goodsListItem) => {
            let findGoodsItemSpecItem = findGoodsItem.goodsItem.find(({ id }) => goodsListItem.goodsItemId === id);
            return {
              goodsName: findGoodsItem?.name || '',
              goodsId: findGoodsItem?.id || '',
              coverUrl: findGoodsItemSpecItem?.coverUrl || '',
              allStock: findGoodsItemSpecItem?.stock || 0,
              salePrice: findGoodsItemSpecItem?.salePrice || 0,
              specValueStr: findGoodsItemSpecItem?.specValueStr || '',
              ...goodsListItem,
            }
          })
          return {
            ...findGoodsItem,
            goodsId: findGoodsItem.id,
            ...item,
            goodsItemList,
          }
        })
      } else {
        this.$moe_msg.error(message);
      }
    },
  },
  mounted() {
    this.req_getFlashDetail();
  }
}
</script>